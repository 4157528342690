import React from 'react';
import homepageImage from '../../Images/Homepage drone image.jpg';
import "../../components/css/SignupForm.css"
const TestBackground = () => (
  <section className="background-image">
    <h1>Test Background</h1>
  </section>
);


export default TestBackground;
